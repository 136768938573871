<template>
  <div class="faq_list">
    <div class="title flex ai-c jc-sb">
      <p>Frequently Asked Question</p>
      <img src="@/assets/images/loanImg/icon_faq.png">
    </div>
    <div class="flex">
      <ul class="hotel-li flex fd-c ai-c">
        <li
          @click="goAnchor(item.link,index)"
          v-for="(item,index) in navData.list"
          :key="index"
          :class="navData.index === index ? 'select-item under-line': 'select-item'"
        >
          <a>{{ item.name }}</a>
        </li>
      </ul>
      <div id="containerList">
        <div id="#selector1" class="container">
          <h3 class="tit" :class="navData.index === 0 ? 'select-item' : ''">Top Questions</h3>
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item v-for="(item, index) in faqList" :id="item.id" :key="index" :title="item.title" :name="item.name">
              <div v-html="item.dec" />
            </el-collapse-item>
          </el-collapse>
        </div>
        <div id="#selector2" class="container">
          <h3 class="tit" :class="navData.index === 1 ? 'select-item' : ''">Loan</h3>
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item v-for="(item, index) in faqList2" :id="item.id" :key="index" :title="item.title" :name="item.name">
              <div v-html="item.dec" />
            </el-collapse-item>
          </el-collapse>
        </div>
        <div id="#selector3" class="container">
          <h3 class="tit" :class="navData.index === 2 ? 'select-item' : ''">Repayment</h3>
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item v-for="(item, index) in faqList3" :id="item.id" :key="index" :title="item.title" :name="item.name">
              <div v-html="item.dec" />
            </el-collapse-item>
          </el-collapse>
        </div>
        <div id="#selector4" class="container">
          <h3 class="tit" :class="navData.index === 3 ? 'select-item' : ''">Other Questions</h3>
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item v-for="(item, index) in faqList4" :id="item.id" :key="index" :title="item.title" :name="item.name">
              <div v-html="item.dec" />
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ['1'],
      faqList: [
        {
          name: '1',
          id: 'question-1',
          title: '1.Q: Why is my loan application not approved?',
          dec: 'A: As much as we’d love to cater to everyone, our loan decisions are based on PesoGo VIP credit and underwriting model, which considers many factors in your loan application.'
        },
        {
          name: '2',
          id: 'question-2',
          title: '2.Q: If my loan is not approved, can I apply again?',
          dec: 'A: If your loan does not pass the audit, you can reapply after 30 days.'
        },
        {
          name: '3',
          id: 'question-3',
          title: '3.Q: How do I make a payment?',
          dec: 'A:To do this, please log in to the PesoGo VIP app and follow the instructions. You can repay your loan via 7Eleven, GCash, Cebuana Lhuillier, SM and many more!'
        },
        {
          name: '4',
          id: 'question-5',
          title: '4.Q: I already made a payment. How long is the posting of my loan payment?',
          dec: 'A:Verification of loan payments will be processed no later than 1 hour after you make a payment. If your payment has not been updated, please fill in the feedback.'
        }
      ],
      faqList2: [
        {
          name: '1',
          id: 'question-1',
          title: '1.Q: How do you apply for a loan?',
          dec: 'A: Please follow all stages of applying for loans in the application and ensure the validity of all your information.'
        },
        {
          name: '2',
          id: 'question-2',
          title: "2.Q: Why can't I receive a verification code?",
          dec: "A: Please ensure that your cellphone is running well, there may be some delays when your operator's server is busy. Please try again later."
        },
        {
          name: '3',
          id: 'question-3',
          title: '3.Q: What is the nominal loan that can be submitted and how long is the loan term?',
          dec: 'A:The nominal loan that can be submitted is up to PHP100,000.00 for a period of 90 days.'
        },
        {
          name: '4',
          id: 'question-4',
          title: '4.Q: How long is the loan approval process?',
          dec: 'A:After completing all the required information, your loan application will be processed as early as 5 minutes but no later than 1-24 hours.'
        },
        {
          name: '5',
          id: 'question-5',
          title: '5.Q: Can my loan limit be raised?',
          dec: 'A:Our first-time loan ranges from 1,000 - 20,000 and can grow higher. To be able to qualify for a higher loan in the future, we advise that you maintain a good credit history by making early or on-time payments.'
        },
        {
          name: '6',
          id: 'question-6',
          title: '6.Q: How do I receive my loan?',
          dec: 'A:Once your loan application is approved, your loan will be disbursed to your registered bank account.'
        },
        {
          name: '7',
          id: 'question-7',
          title: '7.Q: After submitting a loan, can it be canceled?',
          dec: 'A:After the loan application is sent, it will be processed automatically by the system and cannot be canceled.'
        },
        {
          name: '8',
          id: 'question-7',
          title: '8.Q: Can I apply for another loan if I have an existing loan?',
          dec: 'A:You can only borrow one at a time. Once you have settled your existing loan repayment, you are more than welcome to apply for another loan immediately.'
        }
      ],
      faqList3: [
        {
          name: '1',
          id: 'question-1',
          title: '1.Q: How do I make a payment?',
          dec: 'A:To do this, please log in to the PesoGo VIP app and follow the instructions. You can repay your loan via 7Eleven, GCash, Cebuana Lhuillier, SM and many more!'
        },
        {
          name: '2',
          id: 'question-2',
          title: '2.Q: I already made a payment. How long is the posting of my loan payment?',
          dec: 'A: Verification of loan payments will be processed no later than 1 hour after you make a payment. If your payment has not been updated, please fill in the feedback.'
        },
        {
          name: '3',
          id: 'question-3',
          title: '3.Q: What happens if I can’t repay on my due date?',
          dec: 'A: Late payment will affect your credit standing if it is not paid on time. Late fees will apply. We encourage you to pay on time to avoid penalties.'
        },
        {
          name: '4',
          id: 'question-4',
          title: '4.Q: Will I receive payment reminders?',
          dec: 'A: We will send a message to remind you of your payments on and before your due date.'
        },
        {
          name: '5',
          id: 'question-5',
          title: '5.Q: I have made a payment, but there is an overpayment. Can I file a refund?',
          dec: 'A: We will process a refund of your overpayment to your account. Please contact our customer service.'
        }
      ],
      faqList4: [
        {
          name: '1',
          id: 'question-1',
          title: "1.Q: Why can't I submit when I fill in how much information?",
          dec: 'A:When you experience any technical issues with the PesoGo VIP app, please fill in the feedback.'
        },
        {
          name: '2',
          id: 'question-2',
          title: '2.Q: Can I change my telephone number and ID card information?',
          dec: 'A:To ensure the authenticity and security of identity information, we are currently unable to facilitate changes to telephone numbers and ID cards.'
        }
      ],
      navData: {
        index: 0,
        list: [
          {
            name: 'Top Questions',
            link: '#selector1'
          },
          {
            name: 'Loan',
            link: '#selector2'
          },
          {
            name: 'Repayment',
            link: '#selector3'
          },
          {
            name: 'Other Questions',
            link: '#selector4'
          }
        ]
      }
    }
  },
  methods: {
    handleChange(val) {
      console.log(val)
    },
    goAnchor(selector, index) {
      this.navData.index = index
      const section = document.getElementById(selector)
      var element = document.getElementById('containerList')
      console.log(section, element)
      if (section && element) {
        const children = element.children
        let topOffset = 0
        for (let i = 0; i < children.length; i++) {
          if (children[i] === section) {
            break
          }
          topOffset += children[i].offsetHeight
        }
        element.scrollTo({ top: topOffset, behavior: 'smooth' })
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.faq_list{
  // min-width: 1200px;
  margin: 0 auto;
  .title{
    background: linear-gradient(72deg, #1CB9A4 0%, #0DA7C5 100%);
    height: 150px;
    padding: 50px 0  50px calc((100% - 1200px) / 2);
    color: white !important;
    font-weight: bold;
    font-size: 30px;
    img{
      width: 350px;
      height: 140px;
      margin: 0;
    }
  }
  .nav-inner{

  }
  img{
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 550px;
  }
  #containerList{
    flex: 3.7;
    padding: 60px 100px;
    overflow: scroll;
    height: 80vh;
  }
  .container{
    // padding: 0 calc((100% - 1200px) / 2) 50px;
    margin-bottom: 40px;
    .tit{
      font-size: 25px;
      line-height: 50px;
      margin-bottom: 20px;
    }
    .select-item{
      color: #007BFF !important;
    }
  }
  .hotel-li {
    background:#F4F3F4;
    flex: 1;
    padding-top: 50px;
    li {
      width: 40%;
      font-size: 16px;
      text-align: left;
      line-height: 80px;
      white-space:nowrap;
      cursor: pointer;
      a{
        color: #333333;
        text-decoration: none;
      }
      &.under-line{
        color: $color-6E60F9;
        a{
          font-weight: bold;
          color: #3286FF;
          position: relative;
          &::after{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -15px;
            content: '';
            width: 100%;
            height: 3px;
            background: #3286FF;
          }
        }
      }
    }
  }
}
:deep(.el-collapse-item__header) {
  background: #EEEEEE;
  padding-left: 20px;
}
:deep(.el-collapse-item__content) {
  padding: 20px 480px 50px 20px;
}
:deep(.el-collapse-item__wrap){
  border: none;
}
@media only screen and (max-width: 665px){
  .faq_list{
    min-width: calc(100vw);
    padding: 0 !important;
      img{
        width:  100%;
        height: 150px;
        // margin: 10px auto;
      }
  }
  .title{
    height: 100px !important;
    img{
      width: 150px !important;
      height: 50px !important;
    }
    font-size: 14px !important;
    padding: 0 0 0 20px !important;
  }
  .hotel-li{
    li{
      width: 100% !important;
      font-size: 12px !important;
      padding: 0 10px;
    }
    padding-top: 30px !important;
  }
  #containerList{
    padding: 30px 10px !important;
  }
  .container{
    .tit{
      font-size: 16px !important;
    }
    margin-bottom:20px !important;
  }
  :deep(.el-collapse-item__header) {
    background: #EEEEEE;
    padding-left: 10px;
    font-size: 12px !important;
    line-height: 24px !important;
    height: auto !important;
    padding: 5px 0 0 12px;
    // height: 75px !important;
  }
  :deep(.el-collapse-item__content) {
    padding: 10px 10px !important;
    font-size: 12px !important;
  }
}
</style>
